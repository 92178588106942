import React, { lazy, Suspense, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { CONFIG } from './global';

// eslint-disable-next-line @typescript-eslint/naming-convention
function AppRoot() {
   const [app, setApp] = useState<JSX.Element>(<></>);

   useEffect(
      () => {
         fetch(
            './config.json',
            {
               'cache': 'no-store',
            },
         )
            .then((response) => response.json())
            .then((json) => {
               Object.assign(CONFIG, json);

               // eslint-disable-next-line @typescript-eslint/naming-convention
               const Application = lazy(() => (
                  import('./App')
               ));

               setApp(<Application />);
            })
            .catch((err) => {
               throw new Error(`Unable to load the application: ${err}`);
            })
         ;
      },
      [],
   );

   return app;
}

const CONTAINER = document.getElementById('root');

if (CONTAINER === null) {
   throw new Error('Element #root not found');
}

const ROOT = createRoot(CONTAINER);

ROOT.render(
   <React.StrictMode>
      <Suspense
         fallback='Loading the application'
      >
         <AppRoot />
      </Suspense>
   </React.StrictMode>,
);
